.footer-container {
    font-family: "Space Grotesk Light";
    background-color: #fff;
    color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 7.5vh;
}

.cright-container {
    width: 100%;
    max-width: 1100px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border-top: 0.5px solid black
}

.cright-container small {
    margin: 0;
    padding: 0;
    font-family: "IBM Plex Mono Light";
    font-size: 12px;
}

.pp-tos-footer {
    margin: 0;
    padding: 0;
    font-family: "IBM Plex Mono Light";
    font-size: 12px;
    color: black;
    text-underline-offset: 3px;
}

.pp-tos-footer:hover {
    color: var(--mainblue);

}

.footer-max {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1100px;
    height: 35vh;
    max-height: 500px;
    margin-top: 50px;
    border-top: 0.1px solid white;
}

.footer-left {
    display: flex;
    justify-content: space-around;
    width: 35%;
    align-items: center;
    margin: 0;
    transition: all 0.3s ease;
}

.footer-right {
    display: flex;
    justify-content: baseline;
    align-items: baseline;
    margin: 0;
    padding: 0;
    height: 100%;
}

.social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.footer-left img {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
}

.devicon-twitter-original2 {
    width: 15px;
    height: 15px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid white;
    background-color: var(--mainblue);
    fill: black;
    margin: 7.5px;
    margin-top: 0;
    margin-bottom: 0;
}

.devicon-linkedin-plain {
    width: 15px;
    height: 15px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid white;
    background-color: white;
    fill: black;
    margin: 7.5px;
    margin-top: 0;
    margin-bottom: 0;
}

.devicon-twitter-original2:hover {
    scale: 1.3;
    background-color: transparent;
    border-color: transparent;
    fill: var(--mainblue);
}

.devicon-linkedin-plain:hover {
    scale: 1.3;
    background-color: transparent;
    border-color: transparent;
    fill: var(--mainblue);
}

.footer-links {
    margin: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-links ul {
    list-style: none;
    font-family: "Inter Light";
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 14px;
}

.foot-header {
    margin: 5px;
    padding: 5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    font-family: "IBM Plex Mono Light";
    border-bottom: 0.1px solid white;
}

.foot-link {
    color: black;
    font-family: "Inter Light";
    background: 
        linear-gradient(to right, transparent, transparent),
        linear-gradient(to right, var(--mainblue),var(--mainblue));
    background-size: 100% 100%, 0 100%;
    background-position: 100% 100%, 0% 100%;
    background-repeat: no-repeat;
    transition: all 0.2s ease;
    text-decoration: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    margin: 5px;
    padding: 5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.foot-link:focus,
.foot-link:hover {
    cursor: pointer;
    background-size: 0 100%, 100% 100%;
    opacity: 200%;
    color: var(--eerieblack);  
}

@keyframes blink2 {
0% {
    background-color: #00FF00;
    box-shadow: none;
}
50% {
    background-color: #00FF00; /* Bright color */
    box-shadow: 0 0 10px 5px #00ff0076; /* Add shadow when bright */
}
100% {
    background-color: #00FF00;
    box-shadow: none;
}
}

.pulse-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Add this line */
    width: 200px;
    margin: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    border: 1px solid var(--cadetGrey);
}

.pulse-container p {
    color: black;
    font-size: 12px;
    font-family: "IBM Plex Mono Light";
}

.pin2 {
    width: 3px;
    height: 3px;
    /* position: absolute; Remove this line */
    /* z-index: 10; Remove this line */
    background: #00FF00;
    border: 1px solid #00FF00;
    border-radius: 50%;
    margin-left: 10px;
}

.pin-effect2 {
    width: 15px;
    height: 15px;
    background: #00ff0076;
    display: block;
    border-radius: 50%;
    opacity: 1;
    animation: pulsate 2.4s ease-out infinite;
    position: absolute; /* Add this line */
    left: 5px; /* Position to the right of pin2 */
    margin: 0;
    padding: 0;
}

.footer-links img {
    width: 15px;
    margin: 0;
    padding: 0;
    margin-left: 5px;
}

@keyframes pulsate {
0% {
    transform: scale(0.1);
    opacity: 0;
}
50% {
    opacity: 1;
}
100% {
    ransform: scale(1.2);
    opacity: 0;
}
}

.footer-right-mobile {
    display: none;
    width: 100%;
}

@media (max-width: 830px) {
    .footer-container {
        height: 15vh;
        display: flex;
    }
    .footer-max {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        padding: 0;
        width: 100%;
        border: none;
    }
    /* FULL SCREEN 100% WIDTH FOOTER LINKS. TOTALLY NEW COMPONENT */
    .footer-right {
        display: none;
    }

    .footer-left {
        display: none;
    }

    .footer-right-mobile {
        height: 90%;
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }

    .footer-right-mobile ul {
        width: 100%;
        margin: 10px;
        height: 100%;
    }

    .footer-right-mobile img {
        width: 15px;
    }

    .foot-header-mobile {
        color: var(--mainblue);
        text-decoration: none;
        list-style: none;
        width: 100%;
    }

    .foot-link-mobile {
        width: 100%;
        color: white;
        text-decoration: none;
        list-style: none;
        padding: 10px;
    }

    .foot-link-mobile:hover,
    .foot-link-mobile:focus {
        background-color: white;
        color: black;
    }

    .cright-container {
        justify-content: space-around;
        border: none;
        font-size: 1rem;
    }

    .social-container {

    }

    .cright-container small {
        font-size: 10px;
        text-align: center;
    }
    .pp-tos-footer {
        display: none;
    }
    .devicon-twitter-original2 {
        width: 15px;
        height: 15px;
    }
    .devicon-linkedin-plain {
        width: 20px;
        height: 20px;
    }
}

@media (max-height: 400px) {
    .footer-max {
        height: 55vh;
    }
    .footer-links {
        margin: 0;
        margin-left: 50px;
    }
    .footer-links ul {
        font-size: 12px;
    }
}

@media (min-width: 1800px) {
    .footer-max {
        max-height: 300px;
    }
}
.title-tool-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.title-tool-input-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 30%;
    height: 50%;
    border-radius: 10px;
    border: 1px solid #8293996f;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.title-ta-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.title-ta-container textarea {
    width: 75%;
    height: 1rem;
    line-height: 1rem;
    padding: 10px;
    margin: 10px;
    resize: none;
    outline: none;
    font-size: 1rem;
    font-family: "Inter Light";
    border-radius: 10px;
    border: 1px solid #8293996f;
    transition: all 0.3s;
}



.title-tool-input-textarea-container textarea:focus,
.title-tool-input-textarea-container textarea:hover {
    border: 1px solid var(--mainblue);
}

.title-tool-submit-button {
    width: 100px;
    height: 35px;
    border-radius: 7.5px;
    background-color: var(--mainblue);
    color: black;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter Light";
    transition: all 0.3s ease;
}

.title-tool-reset-button {
    width: 100px;
    height: 35px;
    border-radius: 7.5px;
    background-color: black;
    color: var(--mainblue);
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter Light";
    transition: all 0.2s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.title-tool-input-container button:hover,
.reset-title-tool-reset-button:hover {
    opacity: 80%;
}
.video-detail {
    width: 100%;
    background-color: #f0f0f0;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.title-thumbnail {
    max-width: 100px;
}

.title-img-open {
    width: 20px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    padding: 5px;
    transition: all 0.3s ease;
}

.title-img-open:hover {
    transform: translate(4px, -4px);
}

.title-img-copy {
    width: 20px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    padding: 5px;
    transition: all 0.3s ease;
}

.video-detail h3 {
    font-family: "Inter Light";
    font-size: 0.85rem;
    color: black;
    width: 50%;
    text-align: left;
    list-style: none;
}

.video-detail a {
    font-family: "Inter Light";
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00ffe175;
    border-radius: 5px;
}

.video-detail button {
    background-color: #00ffe175;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.video-detail a:hover .title-img-copy-open, .video-detail button:hover .title-img-copy-open{
    background-color: var(--mainblue);
}

.title-tool-answer-container {
    width: 40%;
    height: 50%;
    max-height: 400px;
    border: 1px solid #8293996f;
    border-radius: 10px;
    overflow-y: scroll;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.title-tool-loader {
    width: 100px;
    height: 100px;
    margin-top: 25%;
}

.title-tool-container-blank-message {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 75%;
    margin: 0;
    padding: 0;
}

.title-tool-container-blank-message img {
    width: 50px;
    margin: 0;
    padding: 0;
}

.title-tool-container-blank-message h1 {
    font-family: 'Inter Light';
    color: black;
    font-size: 2rem;
    margin: 0;
    padding: 0;
}

.title-tool-container-blank-message p {
    color: black;
    font-family: 'Inter Light';
    list-style: none;
    margin: 0;
    padding: 5px;
    border: 1px dashed #8293996f;
    border-radius: 5px;
}

.title-tool-container-blank-message ul {
    color: black;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px dashed #8293996f;
    border-radius: 5px;
    padding: 5px;
    margin-top: 30px;
}

.title-tool-container-blank-message ul li {
    font-family: "Inter Light";
    font-size: 1rem;
    padding: 5px;
    padding-bottom: 10px;
}

.title-tool-answer-container::-webkit-scrollbar {
    width: 8px;
}

.title-tool-answer-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

.title-tool-answer-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.title-tool-answer-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.bottom-banner-container {
    width: 100%;
    height: 250px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--mainblue);
  }
  

.bottom-banner-container h1 {
    font-family: "DM Sans";
    color: black;
    font-size: 3rem;
    font-weight: 350;
    text-align: center;
    padding-bottom: 100px;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.get-started-button {
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 50px;
    border: none;
    margin: 10px;
    background-color: black;
    color: var(--mainblue);
    font-family: "Inter Light";
    border: 1px solid black;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.view-faq-button {
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 50px;
    border: none;
    background-color: white;
    color: black;
    font-family: "Inter Light";
    border: 1px solid white;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
}

.right-arrow {
    height: 20px;
    margin: 0;
    margin-left: 5px;
    padding: 0;
    transition: all 0.3s ease;
}

.view-faq-button:hover .right-arrow {
    transform: translate(5px, 0px);
}

.get-started-button:hover .right-arrow {
    transform: translate(5px, 0px);
}

@media (max-width: 768px) {
    .bottom-banner-container {
        height: 50vh;
    }

    .bottom-banner-container h1 {
        font-size: 2rem;
    }
}

.taaft-banner-container {
    width: 100%;
    height: 125px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.taaft-banner-container img {
    padding-top: 15vh;
    width: 250px;
}
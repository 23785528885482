.gobbler-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-message {
    display: none;
}

.gobbler-container-knowledge-base-sidebar {
    width: 25%;
    max-width: 300px;
    height: 100%;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #f0f0f0;
    user-select: none;
    margin: 0;
}

.gobbler-container-knowledge-base-sidebar-header-inner {
    width: 75%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.gobbler-container-knowledge-base-sidebar-header {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 25px;
    background-color: #f0f0f0;
}

.gobbler-container-knowledge-base-sidebar-header h4 {
    color: black;
    font-family: "Inter Light";
    font-weight: 100;
    font-size: 0.85rem;
    margin: 0;
}

.gobbler-container-knowledge-base-sidebar-header-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.plus-kb-button {
    width: 25px;
    height: 25px;
    outline: none;
    border: 1px solid black;
    color: black;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.15s ease;
    text-align: center;
    font-size: 1rem;
    font-family: "IBM Plex Mono Light";
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
}

.plus-kb-button:hover {
    border-color: black;
    color: black;
    background-color: #f0f0f0;
}

.edit-button {
    width: 25px;
    height: 25px;
    outline: none;
    border: 1px solid black;
    color: black;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.15s ease;
    text-align: center;
    margin: 2px;
}

.edit-button:hover {
    border-color: black;
    color: black;
    background-color: #f0f0f0;
}

.edit-button:hover .pencil {
    opacity: 100%;
}

.pencil {
    width: 75%;
    height: 100%;
    background-color: transparent;
    margin: 0;
    padding: 0;
    opacity: 50%;
    user-select: none;
    -webkit-user-drag: none;
}

.edit-button-editing {
    width: 25px;
    height: 25px;
    background-color: #00ffe175;
    outline: none;
    border: 1px solid black;
    color: black;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.15s ease;
    text-align: center;
    margin: 2px;
}

.edit-button-editing .pencil {
    opacity: 100%;
}

.delete-folder-button {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    margin: 3px;
    padding: 0;
    background-color: black;
    outline: none;
    border: 1px solid black;
    border-radius: 5px; 
    cursor: pointer;
}

.delete-folder-button img {
    width: 75%;
    height: 100%;
}

.delete-folder-button:hover {
    opacity: 75%;
}

.database-container {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;  
}

.database-container input {
    width: 25px;
    height: 25px;
    outline: none;
    cursor: pointer;
}

.gobbler-container-knowledge-base-sidebar-li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border: 1px solid #8293996f;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    transition: all 0.15s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.gobbler-container-knowledge-base-sidebar-li-files-ul {
    max-height: 200px;
    width: 100%;
    list-style: none;
    overflow-y: scroll;
    border-top: 1px solid #f0f0f0;
    margin: 0;
}

.gobbler-container-knowledge-base-sidebar-li-files-ul-li-scroll-container {
    width: 100%;
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.gobbler-container-knowledge-base-sidebar-li-files-ul-li-scroll-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .gobbler-container-knowledge-base-sidebar-li-files-ul-li-scroll-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  
  .gobbler-container-knowledge-base-sidebar-li-files-ul-li-scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .gobbler-container-knowledge-base-sidebar-li-files-ul-li-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.gobbler-container-knowledge-base-sidebar-li-files-ul-li {
    color: black;
    font-family: "IBM Plex Mono Light";
    font-size: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
}

.gobbler-container-knowledge-base-sidebar-li-files-ul-li a {
    color: black;
}

.gobbler-container-knowledge-base-sidebar-li-files-ul::-webkit-scrollbar {
    width: 8px;
}
  
.gobbler-container-knowledge-base-sidebar-li-files-ul::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

.gobbler-container-knowledge-base-sidebar-li-files-ul::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}
  
.gobbler-container-knowledge-base-sidebar-li-files-ul::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.gobbler-container-knowledge-base-sidebar-li-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Inter Light";
    font-size: 0.85rem;
    color: black;
}

.gobbler-container-knowledge-base-sidebar-li-top-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
}

.gobbler-container-knowledge-base-sidebar-li-top-buttons-container-standard-button {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    margin: 3px;
    padding: 0;
    background-color: transparent;
    outline: none;
    border: 1px solid black;
    border-radius: 3px; 
    cursor: pointer;
    font-family: "IBM Plex Mono Light";
}

.gobbler-container-knowledge-base-sidebar-li-top-buttons-container-standard-button:hover {
    background-color: #00ffe175;
}

.gobbler-container-knowledge-base-sidebar-li-top-buttons-container-standard-button-upload {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    margin: 3px;
    padding: 0;
    background-color: transparent;
    outline: none;
    border: 1px solid black;
    border-radius: 3px; 
    cursor: pointer;
    font-family: "IBM Plex Mono Light";
    font-size: 0.75rem;
    padding: 2px;
}

.gobbler-container-knowledge-base-sidebar-li-top-buttons-container-standard-button-upload:hover {
    background-color: black;
    color: var(--mainblue);
}

.kb-selector-img {
    width: 17.5px;
    margin: 0;
    padding: 0;
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
    -webkit-user-drag: none;
}

.kb-selector-img.rotate {
    transform: rotate(-270deg);
    transition: transform 0.3s ease;
}

.gobbler-container-inference-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.selected-folder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    background-color: #00ffe175;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.database-container:hover .gobbler-container-knowledge-base-sidebar-li {
    background-color: #00ffe175;
    border-color: #00ffe175;
}

.question-asker-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 60%;
    height: 85vh;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
}

.question-asker-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .question-asker-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  
  .question-asker-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .question-asker-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  

.question-asker-container h4 {
    font-family: "Inter Regular";
    color: black;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
}

.inference-input {
    width: 45vw; /* Adjust based on desired responsiveness */
    border: 1px solid #8293996f;
    background-color: white;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
  
  .inference-input textarea {
    text-align: left;
    border: none;
    outline: none;
    width: 80%;
    margin: 10px;
    font-size: 1rem;
    font-family: "DM Sans";
    font-weight: 300;
    resize: none;
    overflow-y: auto;
    max-height: 200px;
  }
  
  .inference-input button {
    width: 35px;
    height: 35px;
    background-color: transparent;
    outline: none;
    padding: 2px;
    margin: 0;
    margin-right: 5px;
    border-radius: 20px;
    border: 1px solid #8293996f;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inference-send-button-img {
    transform: rotate(-90deg);
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    transition: all 0.3s ease;
    -webkit-user-drag: none;
  }
  
  .inference-input button:hover {
    background-color: #00ffe175;
  }
  
  .inference-input button:hover .inference-send-button-img {
    translate: 0px -3px;
  }
  

.gobbler-question-container {
    color: black;
    font-family: "Inter Light";
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-top: 10px;
}

.gobbler-question-inner-container {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    padding: 15px;
    border-radius: 10px;
    background-color: #F0F0F0;
}

.gobbler-question {
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
}

.gobbler-answer-container {
    width: 95%;
    max-width: 750px;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
}

.gobbler-question-container-image-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
}

.gobbler-answer {
    color: black;
    font-family: "Inter Light";
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 0;
}

.gobbler-answer * {
    margin-bottom: 16px;
    font-family: 'Inter Light';
  }

.remove-file-button {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    margin: 3px;
    padding: 0;
    background-color: transparent;
    outline: none;
    border: 1px solid black;
    border-radius: 5px; 
    cursor: pointer;
    font-size: 0.55rem;
    transition: all 0.15s ease;
}

.remove-file-button:hover {
    cursor: pointer;
}

.files-label {
    color: black;
    text-align: center; 
    font-family: 'IBM Plex Mono Light';
    margin: 0; 
    padding: 0;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 0.75rem;
    width: 100%;
}

.sac-message {
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.sac-h1 {
    font-size: 1.5rem;
    font-family: "Inter Light";
    color: black;
    padding: 25px;
}

.sac-selected-kb {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 5px;
}

.sac-selected-kb img {
    width: 25px;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
}

.sac-selected-kb p {
    color: #00ffe1;
    font-family: "IBM Plex Mono Light";
    font-size: 0.75rem;
    padding: 5px;
}


.sac-message-logo {
    width: 30px;
    background-color: black;
    border-radius: 5px;
    padding: 5px;

}

.gobble-cright {
    position: absolute;
    bottom: 1%;
    right: 25%;
    color: black;
    font-size: 10px;
    font-family: "IBM Plex Mono Light";
}

.current-model-signage-container {
    color: var(--mainblue);
    font-family: "Inter Light";
    font-size: 0.85rem;
    position: absolute;
    top: 100px;
    right: 50px;
    background-color: black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.current-model-signage-container img {
    width: 25px;
    padding: 5px;
    cursor: pointer;
}   

@media (max-width: 1500px) {
    .gobbler-container-knowledge-base-sidebar {
        width: 35%;
    }
    .inference-input {
        right: 9.5%;
    }
}

@media (max-width: 1350px) {
    .gobbler-container-knowledge-base-sidebar {
        width: 30%;
    }   

    .gobbler-container-knowledge-base-sidebar-header {
        flex-direction: column;
        justify-content: center;
    }

    .gobbler-container-knowledge-base-sidebar-header h4 {
        margin-bottom: 10px;
    }

    .gobbler-container-knowledge-base-sidebar-header-button-container {
        width: 50%;
    }
}

.gobbler-answer-container-image-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
}

.ai-message-logo {
    width: 18px;
    height: 18px;
    background-color: black;
    padding: 0.25rem;
    border-radius: 5px;
    text-align: left;
    position: relative;
    margin: 0;
}

@media (max-width: 950px) {
    .gobbler-container {
        display: none;
    }

    .desktop-message {
        color: black;
        font-size: 2rem;
        font-family: "Inter Light";
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}









/* Modal Styling Updates */

.modal-selection-container-file-upload-container-h1 {
    font-size: 2rem;
    font-family: "Inter Light";
    font-weight: 100;
    padding-bottom: 25px;
}

.modal-selection-container-file-upload-container-outer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-selection-container-file-upload-container {
    width: 50%;
    height: 90%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #8293996f;
}

.modal-selection-container-ytchannel-upload-container {
    width: 50%;
    height: 90%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.modal-selection-container-file-upload-container img,
.modal-selection-container-ytchannel-upload-container img {
    width: 400px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.modal-selection-container-file-upload-container-file-types {
    width: 300px;
    display: flex;
    justify-content: space-around;
    background-color: #F0F0F0;
    align-items: center;
    margin: 0;
    border-radius: 10px;
    border: 1px dashed var(--cadetGrey);
    padding: 10px;
}

.modal-selection-container-file-upload-container-file-types img {
    width: 50px;
    padding: 5px;
    margin: 0;
    box-shadow: none;
}

.modal-selection-container-file-upload-container h4,
.modal-selection-container-ytchannel-upload-container h4 {
    font-family: "Inter Light";
    font-size: 1rem;
    color: black;
    margin: 0;
    padding: 10px;
    background-color: #F0F0F0;
    border: 1px dashed var(--cadetGrey);
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.modal-selection-container-file-upload-container small,
.modal-selection-container-ytchannel-upload-container small {
    font-family: "Inter Light";
    font-size: 0.85rem;
    color: black;
    margin: 0;
    padding: 0;
}

.modal-selection-container-file-upload-container button,
.modal-selection-container-ytchannel-upload-container button {
    width: 100px;
    padding: 10px;
    background-color: #00ffe175;
    border: none;
    outline: none;
    color: black;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.15s ease;
    text-align: center;
    font-size: 0.85rem;
    font-family: "Inter Light";
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: all 0.2s ease;
}

.modal-selection-container-file-upload-container button:hover,
.modal-selection-container-ytchannel-upload-container button:hover {
    background-color: black;
    color: var(--mainblue);
} 

.standard-upload-modal-button {
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border-radius: 5px;
    outline: none;
    font-family: "Inter Light";
    border: 1px solid #8293996f;
    transition: all 0.2s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
}

.standard-upload-modal-button:hover {
    background-color: #f0f0f0;
    color: black;
}

.standard-upload-modal-button-submit,
.channel-upload-modal-button-submit {
    width: 100%;
    padding: 10px;
    background-color: #00ffe175;
    border-radius: 5px;
    outline: none;
    font-family: "Inter Light";
    border: none;
    transition: all 0.2s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 10px;
    cursor: pointer;
}

.standard-upload-modal-button-submit:hover,
.channel-upload-modal-button-submit:hover {
    background-color: black;
    color: var(--mainblue);
}

.standard-upload-modal-textarea,
.channel-upload-modal-input {
    width: 398px;
    height: 40px;
    border: 1px solid #8293996f;
    background-color: white;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    overflow: hidden;
    resize: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;  
    transition: all 0.2s ease;
    font-family: "Inter Light";
}

.standard-upload-modal-textarea:focus,
.standard-upload-modal-textarea:hover,
.channel-upload-modal-input:focus,
.channel-upload-modal-input:hover {
    border-color: var(--mainblue);
}

.standard-upload-modal-textarea::placeholder,
.channel-upload-modal-input::placeholder {
    color: #8293996f;
    font-family: "Inter Light";
    font-size: 1rem;
    padding: 10px;
}

.standard-upload-modal-textarea:focus::placeholder,
.channel-upload-modal-input:focus::placeholder {
    color: transparent;
}

#name {
    width: 398px;
    height: 40px;
    border: 1px solid #8293996f;
    background-color: white;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    overflow: hidden;
    resize: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;  
    transition: all 0.2s ease;
    font-family: "Inter Light";
}

#name:focus,
#name:hover {
    border-color: var(--mainblue);
}

#name::placeholder {
    color: #8293996f;
    font-family: "Inter Light";
    font-size: 1rem;
    padding: 10px;
}

#name:focus::placeholder {
    color: transparent;
}

.create-kb-button-submit {
    width: 100%;
    padding: 10px;
    background-color: #00ffe175;
    border-radius: 5px;
    outline: none;
    font-family: "Inter Light";
    border: none;
    transition: all 0.2s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 10px;
    cursor: pointer;
}

.create-kb-button-submit:hover {
    background-color: black;
    color: var(--mainblue);
}
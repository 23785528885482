.resume-tool-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.resume-tool-container form {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #8293996f;
    padding: 50px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.resume-tool-container form small {
    color: black;
    font-family: "Inter Light";
}

.resume-tool-container input[type="file"] {
    display: none;
}

.summarizer-url-input {
    padding: 10px;
    border-radius: 7.5px;
    outline: none;
    font-family: "Inter Light";
    border: 1px solid #8293996f;
    transition: all 0.3s ease;
    margin-bottom: 10px;
}

.summarizer-url-input:focus,
.summarizer-url-input:hover {
    border-color: #00ffe175;
}

.summarizer-url-input::placeholder {
    color: #8293996f;
}

.custom-file-upload {
    width: 35px;
    height: 35px;
    border-radius: 7.5px;
    background-color: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    transition: all 0.3s ease;
    border: 1px solid #8293996f;
    margin: 10px;
    margin-bottom: 50px;
}

.summary-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    border: 1px solid #8293996f;
    color: black;
    font-family: "Inter Light";
    font-size: 0.85rem;
    margin-bottom: 50px;
}

.summary-buttons-container label {
    background-color: white;
    margin-top: -7.5px;
    padding: 10px;
    padding-top: 0;
    padding-bottom: 0;
}

.summary-buttons-inner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-buttons-container button {
    width: 30px;
    height: 30px;
    border-radius: 7.5px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #8293996f;
    cursor: pointer;
    background-color: white;
}

.summary-buttons-inner button.active {
    background-color: #00ffe175;
}

.summary-buttons-container button img {
    width: 25px;
    height: 25px;
    margin: 0;
    padding: 0;
}

.custom-file-upload:hover {
    background-color: #00ffe175;
} 

.upload {
    padding-top: 3px;
    transition: all 0.3s ease;
    width: 20px;
}

.summary-buttons-container button {
    background-color: white;
    border: 1px solid #8293996f;
    outline: none;
    border-radius: 5px;
    padding: 5px;
    font-family: "Inter Light";
    transition: all 0.3s ease;
    cursor: pointer;
    margin: 10px;
}

.summary-buttons-container button:hover {
    background-color: #00ffe175;
}

.summary-answer-container {
    color: black;
    margin: 0;
    padding: 0;
    width: 450px;
    text-align: left;
    border: 1px solid #8293996f;
    height: 400px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-family: "Inter Light";
    line-height: 2rem;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-answer-inner-container {
    height: 100%;
}

.summary-answer-container-blank-message {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 50%;
    margin: 0;
    padding: 0;
}

.summary-answer-container-url-uploaded {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 50%;
    margin: 0;
    padding: 0;
}

.summary-answer-container-url-uploaded img {
    width: 75px;
}

.summary-answer-container-blank-message img {
    width: 50px;
    margin: 0;
    padding: 0;
}

.summary-answer-container-blank-message h1 {
    font-family: 'Inter Light';
    color: black;
    font-size: 2rem;
    margin: 0;
    padding: 0;
}

.summary-answer-container-blank-message p {
    color: black;
    font-family: 'Inter Light';
    list-style: none;
    margin: 0;
    padding: 5px;
    border: 1px dashed #8293996f;
    border-radius: 5px;

}

.summary-focus-buttons-container {
    width: 200px;
    margin-bottom: 20px;
    border: 1px solid #8293996f;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.summary-focus-buttons-container button {
    margin: 0;
    padding: 0;
    padding: 5px;
    margin: 5px;
    background-color: transparent;
    font-family: "Inter Light";
    border: 1px solid #8293996f;
    border-radius: 5px;
    outline: none;
    transition: all 0.2s ease;
    cursor: pointer;
}

.summary-focus-buttons-container button:hover {
    background-color: #00ffe175;
}

.summary-focus-buttons-container label {
    background-color: white;
    margin-top: -22.5px;
    padding: 10px;
    padding-top: 0;
    padding-bottom: 10px;   
    color: black;
    font-family: "Inter Light";
    font-size: 0.85rem;
}

.summarize-submit-button {
    margin: 0;
    padding: 0;
    padding: 5px;
    margin: 5px;
    background-color: transparent;
    font-family: "Inter Light";
    border: 1px solid #8293996f;
    border-radius: 5px;
    outline: none;
    transition: all 0.2s ease;
    cursor: pointer;
}

.summarize-submit-button:hover {
    background-color: #00ffe175;
}


.summary-focus-buttons-container button.active {
    background-color: #00ffe175;
}

.summary-answer-container::-webkit-scrollbar {
    width: 8px;
}

.summary-answer-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

.summary-answer-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.summary-answer-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.summarizer-status-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
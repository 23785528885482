.background-container {
  background-color: #fff;
  background-image: url(../assets/q5.svg);
    background-size: cover;
    background-position: bottom;
    background-repeat: repeat;

}

/* FullHeightComponent.css */
.full-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: black;
}

.title-section {
  text-align: center;
  margin-top: 20px; /* Adjust spacing as needed */
  font-family: "Inter Light";
  color: black;
}

.title-section h1 {
  margin-bottom: 10px;
  font-size: 3rem;
  font-family: "DM Sans",sans-serif;
  font-weight: 350;
}

.title-section h4 {

}

.tiles-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
}

.tile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25%;
  max-height: 200px;
  height: 50%;
  margin: 20px;
  padding: 0;
  border-radius: 15px;
  font-family: "Inter Light";
  background-color: white;
  box-shadow: 0 0 20px 5px #00ffe17b;
  border: 1px solid #f0f0f0;
}

.tile-top-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  padding-bottom: 20px;
}

.tile-top-section h4 {
  margin: 0;
  padding: 0;
  border-radius: 5px;
  padding: 5px;
  font-family: "Inter Light", sans-serif;
}

.tile-top-section img {
  width: 40px;
  margin: 0;
  padding: 0;
  background-color: #00ffe17b;
  padding: 10px;
  border-radius: 7.5px;
  box-shadow: #f0f0f0 5px 5px;
}

.transcribe-small1 {
  width: 63%;
  margin-bottom: 15px;
}

.transcribe-small2 {
  width: 63%;
  margin-top: 15px;
}

.transcribe-small3 {
  width: 80%;
  margin-top: 15px;
}

.tile ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  font-family: "IBM Plex Mono Light";
  border-radius: 7.5px;
  background-color: black;
}

.tile ul li {
  font-size: 12px;
  margin: 5px;
  padding: 5px;
  color: var(--mainblue);
}

.tile-bottom-row {
  width: 100%;
  height: 50%; /* Remaining height */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 1200px) {
  .tiles-container {
    flex-direction: column;
  }

  .tile-bottom-row {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .tile {
    width: 50%;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .tile {
    width: 85%;
    height: 100%;
  }

  .full-container {
    height: 125vh;
    justify-content: center;
  }

  .full-width-div-tile {
    width: 90%;
    height: 100%;
  }

  .background-container {
    background-size: 500%;
  }
}

@media (max-width: 400px) {
  .full-container {
    height: 175vh;
  }
}
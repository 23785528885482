.about-container {
    width: 100%;
    height: 100vh;
    background-image: url(../assets//tdp-hero.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.arrow-container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.arrow-container img {
    width: 40px;
    margin: 75px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 50%;
}

.arrow-container img:hover {
    transform: translate(-10px, 0px);
}

.about-left {
    height: 95%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.about-text {
    margin: 75px;
    margin-left: 150px;
}

.about-text a {
    color: black;
}

.about-text h1 {
    margin-top: -50px;
    margin-bottom: 25px;
    font-size: 4rem;
    font-family: "Space Grotesk Light";
}

.about-text h4 {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 2rem;
    font-family: "IBM Plex Mono Light";
}

.about-text p {
    font-size: 14px;
    font-family: "Inter Light";
    line-height: 1.5rem;
    color: grey;
    padding: 5px;
}

@media (max-width: 1120px) {
    .about-text h1 {
        margin-top: 0;
    }
    .about-left {
        width: 100%;
    }
    .about-text {
        margin-left: 75px;
    }
}

@media (max-width: 600px) {
    .about-container {
        height: 125vh;
        background-position: bottom;
    }

    .about-text {
        width: 90%;
        margin: 0;
    }
    .about-text h1 {
        font-size: 3rem;
    }

    .arrow-container img {
        margin: 25px;
    }

}


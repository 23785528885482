.file-deletion-container {    
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-deletion-container label {
    position: absolute;
    bottom: 7.5%;
    left: 14px;
    color: black;
    padding: 10px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 5px;
    border: 0.5px solid black;
    background-color: #00ffe175;
    font-family: "Inter Light";
    font-size: 0.85rem;
    opacity: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.duplicate-folder-container,
.question-limit-message-container label {
    position: absolute;
    bottom: 7.5%;
    left: 14px;
    color: black;
    padding: 10px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 5px;
    border: 0.5px solid black;
    background-color: rgba(255, 0, 0, 0.352);
    font-family: "Inter Light";
    font-size: 0.85rem;
    opacity: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.max-kb-container label {
    position: absolute;
    bottom: 7.5%;
    left: 14px;
    color: black;
    padding: 10px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 5px;
    border: 0.5px solid black;
    background-color: rgba(255, 0, 0, 0.352);
    font-family: "Inter Light";
    font-size: 0.85rem;
    opacity: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.file-deletion-container img {
    width: 17.5px;
    margin-left: 15px;
}

.question-limit-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.question-limit-message-container label {
    text-align: left;
}

.question-limit-message-container a {
    color: black;
}

.question-limit-message-container img {
    width: 20px;
    -webkit-user-drag: none;
}
.kb-new-user-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50%;
}

.kb-new-user-container small {
    color: black;
    font-family: "Inter Light";
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    border: 1px dashed var(--cadetGrey);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
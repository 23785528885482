/* font-preload.css */
@font-face {
    font-family: 'Space Grotesk Regular';
    src: url("./assets/fonts/SpaceGrotesk-Regular.ttf");
}

@font-face {
    font-family: 'Space Grotesk Light';
    src: url("./assets/fonts/SpaceGrotesk-Light.ttf");
}

@font-face {
    font-family: 'Inter Regular';
    src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: 'Inter Semi Bold';
    src: url("./assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
    font-family: 'Inter Light';
    src: url("./assets/fonts/Inter-Light.ttf");
}

@font-face {
    font-family: 'Inter Thin';
    src: url("./assets/fonts/Inter-Thin.ttf");
}

@font-face {
    font-family: 'IBM Plex Mono Reg';
    src: url("./assets/fonts/IBMPlexMono-Regular.ttf");
}

@font-face {
    font-family: 'IBM Plex Mono Thin';
    src: url("./assets/fonts/IBMPlexMono-Thin.ttf");
}

@font-face {
    font-family: 'IBM Plex Mono Light';
    src: url("./assets/fonts/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: 'IBM Plex Mono Extra Light';
    src: url("./assets/fonts/IBMPlexMono-ExtraLight.ttf");
}

@font-face {
    font-family: 'IBM Plex Sans Thin';
    src: url("./assets/fonts/IBMPlexSans-Thin.ttf");
}

@font-face {
    font-family: 'IBM Plex Sans Light';
    src: url("./assets/fonts/IBMPlexSans-Light.ttf");
}

@font-face {
    font-family: 'IBM Plex Sans Reg';
    src: url("./assets/fonts/IBMPlexSans-Regular.ttf");
}

/* Preload fonts */
@font-face {
    font-family: 'Space Grotesk Regular';
    src: url("./assets/fonts/SpaceGrotesk-Regular.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'Space Grotesk Light';
    src: url("./assets/fonts/SpaceGrotesk-Light.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'Inter Regular';
    src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'Inter Semi Bold';
    src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'Inter Light';
    src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'Inter Thin';
    src: url("./assets/fonts/Inter-Thin.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'IBM Plex Mono Reg';
    src: url("./assets/fonts/IBMPlexMono-Regular.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'IBM Plex Mono Thin';
    src: url("./assets/fonts/IBMPlexMono-Thin.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'IBM Plex Mono Light';
    src: url("./assets/fonts/IBMPlexMono-Light.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'IBM Plex Mono Extra Light';
    src: url("./assets/fonts/IBMPlexMono-ExtraLight.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'IBM Plex Sans Thin';
    src: url("./assets/fonts/IBMPlexSans-Thin.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'IBM Plex Sans Light';
    src: url("./assets/fonts/IBMPlexSans-Light.ttf") format("truetype");
    font-display: swap;
    as: font;
}

@font-face {
    font-family: 'IBM Plex Sans Reg';
    src: url("./assets/fonts/IBMPlexSans-Regular.ttf") format("truetype");
    font-display: swap;
    as: font;
}

.faq-container {
  width: 100%;
  min-height: 120vh; /* Increase if needed to always be larger than your expanded accordion */
  padding-top: 50px; /* Optional spacing at the top */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Keeps content at the top instead of re-centering */
  background-color: #fff;
  background-image: url(../assets/faqparticles.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}


.faq-container h1 {
  width: 50%;
  font-size: 3rem;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-weight: 350;
  padding: 25px;
}

.faq-container small {
  width: 50%;
  padding: 25px;
  padding-top: 0;
  font-size: 14px;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Inter Light";
  display: none;
}

.accordion {
  width: 50%;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  font-weight: 500;
}

.accordion-item {
  width: 100%;
  margin: 0;
  padding: 0;
  color: black;
}

.accordion-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  cursor: pointer;
  border-radius: 0px;
  border: none;
  background-color: white;
  color: black;
  font-size: 16px;
  border-bottom: 0.5px solid #f0f0f0;
  font-family: "DM Sans", sans-serif;
  font-weight: 350;
  transition: transform 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
}

.accordion-question:hover {
  border-color: var(--mainblue);
}

.accordion-answer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  margin: 0;
  list-style: none;
  color: #424B54;
  font-family: "Inter Light";
  overflow: scroll;
  transition: max-height 0.4s ease-in-out;
}

.accordion-answer li {
  margin: 15px;
  font-size: 16px;
}

.accordion-answer li a {
  color: #424B54;
  height: 30px;
  margin: 0;
  padding: 0;
  text-underline-offset: 5px;
}

.accordion-item.open .accordion-answer {
  max-height: 100%;
}

/* Style for the rotated image when accordion is open */
.accordion-icon.rotate {
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .faq-container {
    height: 120vh;
    margin-top: 0px;
    background-size: 200%;
  }

  .faq-container h1 {
    font-size: 3rem;
    width: 80%;
    margin: 0;
  }

  .faq-container small {
    width: 80%;
    margin: 0;
  }

  .accordion {
    width: 80%;
  }

  .accordion-item {
    width: 100%;
    text-align: left;
  }
}

.accordion-answer::-webkit-scrollbar {
  width: 8px;
}

.accordion-answer::-webkit-scrollbar-track {
  background: #ffffff;
}

.accordion-answer::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 4px;
}

.accordion-answer::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
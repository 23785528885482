:root {
    --grey: #2F2F2F;
    --eerieblack: #222222;
    --mainblue: #00FFE1;
    --white: #F8F7FF;
    --kusama: #E6007A;
    --prussianBlue: #212D40;
    --richBlack: #11151C;
    --lightCyan: #D0F4EA;
    --cadetGrey: #829399;
    --greyweb: #7E8287;
    --oldrose: #B98389;
    --aliceblue: #E1F2FE;
    --melon: #F7AF9D;
    --puce: #C08497;
    --maxbluegreen: #75B9BE;
    --jet: #373737;
    --jet2: #2F2D2E;
    --platinum: #E0E1DD;
    --isabelline: #F1F0EA;
    --header: "Space Grotesk Light";
    --subHeader: "IBM_MEDIUM";
    --plainText: "IBM_EXTRA_LIGHT";
    --d: 2500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgba(168, 239, 255, 1);
	--c2: rgba(168, 239, 255, 0.1);
    --startColor: #00ffe1;
    --stopColor: #3ca89b;
} 

@font-face {
    font-family: 'Space Grotesk Regular';
    src: url("./assets/fonts/SpaceGrotesk-Regular.ttf");
}
  
@font-face {
    font-family: 'DM Sans';
    src: url("./assets/fonts/DMSans.ttf");
}
  
@font-face {
    font-family: 'Space Grotesk Light';
    src: url("./assets/fonts/SpaceGrotesk-Light.ttf");
}
  
@font-face {
    font-family: 'Inter Regular';
    src: url("./assets/fonts/Inter-Regular.ttf");
}
  
@font-face {
    font-family: 'Inter Semi Bold';
    src: url("./assets/fonts/Inter-SemiBold.ttf");
}
  
@font-face {
    font-family: 'Inter Light';
    src: url("./assets/fonts/Inter-Light.ttf");
}
  
@font-face {
    font-family: 'Inter Thin';
    src: url("./assets/fonts/Inter-Thin.ttf");
}
  
@font-face {
    font-family: 'IBM Plex Mono Reg';
    src: url("./assets/fonts/IBMPlexMono-Regular.ttf");
}
  
@font-face {
    font-family: 'IBM Plex Mono Thin';
    src: url("./assets/fonts/IBMPlexMono-Thin.ttf");
}
  
@font-face {
    font-family: 'IBM Plex Mono Light';
    src: url("./assets/fonts/IBMPlexMono-Light.ttf");
}
  
@font-face {
    font-family: 'IBM Plex Mono Extra Light';
    src: url("./assets/fonts/IBMPlexMono-ExtraLight.ttf");
}
  
@font-face {
    font-family: 'IBM Plex Sans Thin';
    src: url("./assets/fonts/IBMPlexSans-Thin.ttf");
}
  
@font-face {
    font-family: 'IBM Plex Sans Light';
    src: url("./assets/fonts/IBMPlexSans-Light.ttf");
}
  
@font-face {
    font-family: 'IBM Plex Sans Reg';
    src: url("./assets/fonts/IBMPlexSans-Regular.ttf");
}

* {
    margin: 0 auto;
    scroll-behavior: smooth;
    padding: 0;
}

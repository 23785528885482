.overarching-hero-container {
    width: 100%;
    background-color: #fff;
    z-index: -2;
    background-image: url(../assets/q5.svg);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}

.hero-container {
    width: 100%;
    max-width: 1100px;
    height: 150vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff; */
}

.hero-text {
    width: 100%;
    -webkit-user-select: none;
    text-align: center;
}

.hero-text h1 {
    color: black;
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 4rem;
}

.hero-text p {
    color: black;
    font-family: "Inter Light";
    font-size: 1.25rem;
}

.button-view-tx {
    font-family: "Inter Light";
    background-color: var(--mainblue);
    color: black;
    transition: all 0.2s ease;
    text-decoration: none;
    margin-top: 5%;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 20px 5px #00ffe17b;
    border: none;
    margin-bottom: 50px;
    font-size: 1rem;
}

.button-view-tx:focus,
.button-view-tx:hover {
    cursor: pointer;
}

.button-view-tx:hover .hero-arrow {
    transform: translate(5px, 0px);
}

.hero-arrow {
    width: 20px;
    margin-bottom: -4.75px;
    margin-left: 2px;
    padding: 0;
    transition: all 0.3s ease;
}

.hero-video {
    margin: 0;
    padding: 0;
    width: 100%;
    border-radius: 10px;
}

.hero-video video {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 20px 5px #f0f0f0;
    border: 2px solid #f0f0f0;
}

.hero-scroller {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hero-scroller h2 {
    color: black;
    font-family: "Inter Light";
    font-size: 2rem;
    margin-bottom: 50px;
}

@media (max-width: 930px) {  
    .overarching-hero-container {
        background-position: center;
    }

    .hero-container {
        flex-direction: column;
        justify-content: space-around;
        height: 85vh;
    }

    .desktop-visual {
        display: none;
    }
    .mobile-visual {
        display: flex;
    }

    .hero-text {
        width: 90%;
        padding-bottom: 10%;
        text-align: center;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }

    .hero-text h1 {
        font-size: 3rem;
        padding-top: 50px;
    }

    .hero-video {
        width: 85%;
    }
}


.scroll {
    position: relative;
    display: flex;
    width: 65%;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent)
}

.scroll div {
    white-space: nowrap;
    animation: scroll var(--time) linear infinite;
    animation-delay: calc(var(--time)*-1);

}

.scroll div:nth-child(2) {
    animation: scroll2 var(--time) linear infinite;
    animation-delay: calc(var(--time)/-2);
}

@keyframes scroll {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes scroll2 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-200%);
    }
}

.scroll div span {
    display: inline-flex;
    margin: 10px;
    background: var(--mainblue);
    color: black;
    font-family: "Inter Light";
    padding: 5px 10px;
    border-radius: 5px;
    transition: 0.5s;
    user-select: none;
    box-shadow: 0 0 10px 2.5px #00ffe17b;
}

.scroll div span:hover {
    background: #00ffe175;
    color: black;
    cursor: pointer;
}

.imgBox div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgBox img {
    max-width: 100px;
    scale: 0.8;
}
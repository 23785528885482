.auth-container {
    width: 100%;
    height: 100vh;
    /* background-image: url(../assets/redirect.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-message-container {
    width: 30%;
    height: 250px;
    background-color: var(--mainblue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    /* background-image: url(../assets/waves.svg);
    background-position: left; 
    background-repeat: no-repeat;
    background-size: 250%; */
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
}

.auth-message {
    color: black;
    font-size: 1rem;
    font-family: "IBM Plex Mono Extra Light";
    padding: 20px;
}

.auth-message-container img {
    width: 40px;
    padding: 7.5px;
    padding-bottom: 40px;
    border-radius: 5px;
}

.tdp-title-container4 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 25px;
    padding-right: 0;
}

.tdp-first4 {
    font-size: 12px;
    font-family: "DM Sans";
    font-weight: 250;
    color: black;
    padding: 3px;
    margin-right: 2%;
    margin-left: 2%;
}

.tdp-second4 {
    margin-left: 0;
    font-size: 12px;
    font-family: "IBM Plex Mono Light";
    color: var(--mainblue);
    border: 1px solid var(--mainblue);
    background-color: black;
    padding: 4px;
    border-radius: 5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
}


@media (max-width: 768px) {
    .auth-message-container {
        width: 85%;
    }
}

        
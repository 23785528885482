.signup-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(../assets/wrench2.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.signup-container h1 {
    width: 100%; 
    text-align: center;
    font-family: "IBM Plex Mono Light";
    font-size: 3rem;
    padding-bottom: 1.5%;
    color: black;
}

.signup-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    max-width: 404px;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form-group {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-group2 {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
    margin: 5%;
    background-color: black;
    border-radius: 5px;
}

.form-group label {
    padding-left: 12px;
    width: 100%;
    color: black;
    font-size: 12px;
    font-family: "Inter Light";
}

.form-group input {
    width: 100%;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 7.5%;
    margin: 3%;
    margin-top: 1.5%;
    outline: none;
    font-family: "Inter Light";
    padding-right: 50px;
}

.form-group2 label {
    padding-left: 12px;
    width: 80%;
    color: white;
    font-size: 0.7rem;
    font-family: "Inter Light";
}

.form-group2 a {
    color: var(--mainblue);
    text-decoration: none;
}

.form-group2 a:hover {
    text-decoration: underline 0.25px;
    text-underline-offset: 2.5px;
}

.form-group2 input {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none; /* Safari/Chrome */
    -moz-appearance: none; /* Firefox */
    border: 1px solid white;
    border-radius: 2px;
    z-index: 1;
    transition: all 0s ease;
    outline: none;
}

.form-group2 input:hover {
    border-color: var(--mainblue);
}

.form-group2 input:checked {
    border-color: var(--mainblue);
}

.form-group2 img {
    width: 17px;
    height: 17px;
    padding: 0;
    margin: 0;
    margin-left: -24.5px;
    padding-bottom: 2px;
    z-index: 0;
    visibility: hidden;
}

.form-group input:hover,
.form-group input:focus {
    border-color: var(--mainblue);
}

.signup-button {
    font-family: "Inter Light";
    font-size: 14px;
    width: 70%;
    padding: 16px;
    background-color: var(--mainblue);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-button:focus,
.signup-button:hover {
    opacity: 0.75;
}

.input-with-icon {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .input-icon1 {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .input-icon {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .input-icon img,
  .input-icon1 img {
    width: 20px;
    height: 20px;
    outline: none;
  }

  .password-toggle {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .password-toggle img {
    width: 20px;
    height: 20px;
    outline: none;
  }

  .signup-check {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    visibility: hidden;
  }

.ahan {
    font-family: "IBM Plex Mono Light";
    margin-top: 7.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    padding: 3px;
}

.ahan a {
    text-decoration: none;
    color: black;
    margin-left: 5px;
    padding: 2px;
    border: 0.25px solid black;
    border-radius: 3px;
    transition: all 0.2s ease;
}

.ahan a:hover {
    color: var(--mainblue);
    background-color: black;
}

.cright2 {
    color: black;
    font-family: "IBM Plex Mono Light";
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
}

.cright2 a {
    color: black;
}

.cright2 a:hover {
    color: var(--mainblue);
}

.cright-spacer {
    padding: 10px;
}

.cright {
    color: white;
    font-family: "Space Grotesk Light";
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
}

.form-container-signup {
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 768px) {
    .signup-form {
        max-width: 350px;
    }

    .form-group2 img {
        margin-left: -22px;
    }

    .cright2 {
        flex-direction: column;
    }
}
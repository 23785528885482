.privacy-policy, .terms-conditions {
    background-color: #f0f0f0;
    color: black;
    font-family: "Inter Regular", sans-serif;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.privacy-policy-inner, .terms-conditions-inner {
    width: 30%;
    height: 80%;
}

.privacy-policy h1, .terms-conditions h1 {
    font-family: "Inter Regular", sans-serif;
    font-weight: 400;
    padding-bottom: 20px;
    text-align: left;
}

.privacy-policy h2, .terms-conditions h2 {
    font-family: "Inter Regular", sans-serif;
    font-weight: 300;
    padding: 10px 0;
}

.privacy-policy-inner-button-container, .terms-conditions-inner-button-container {
    width: 100%;
    text-align: left;
    padding-bottom: 50px;
}

.privacy-policy-inner button, .terms-conditions-inner button {
    width: 100px;
    height: 30px;
    background-color: transparent;
    border: 1px solid black;
    color: black;
    outline: none;
    border-radius: 5px;
    font-family: "Inter Regular", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;
}

.pp-img-arrow, .tc-img-arrow {
    width: 25px;
    transform: rotate(180deg);
    margin: 0;
    margin-right: 5px;
    transition: all 0.2s ease;
}

.privacy-policy-inner button p, .terms-conditions-inner button p {
    margin: 0;
}

.privacy-policy-inner button:hover .pp-img-arrow, .terms-conditions-inner button:hover .tc-img-arrow {
    transform: translate(-10px) rotate(180deg);
}

.privacy-policy p, .terms-conditions p {
    line-height: 1.6;
    margin-bottom: 20px;
}

.privacy-policy a, .terms-conditions a {
    color: black;
    text-decoration: underline;
}

.privacy-policy section, .terms-conditions section {
    margin-bottom: 20px;
}

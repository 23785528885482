.resources-dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border: none;
    width: 200px;
    height: 250px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s, transform 0.3s;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
}

.resources-dropdown.show {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    transform: translate(-50%, 15px);
}

.caret-icon {
    transform: translate(0px, 0px);
    height: 8px;
    width: 24px;
    position: absolute;
    top: 37.5px;
    left: 41px;
    margin: 0;
    padding: 0;
    background: url(../assets/caret.svg) no-repeat;
    opacity: 0;
    transition: opacity 0.3s, visibility 0s 0.3s, transform 0.3s;
}

.resources-dropdown.show .caret-icon {
    opacity: 0;
    visibility: visible;
}

.caret-icon.show {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 10px);
    transition-delay: 0s;
}

.resource-warning-img {
    width: 15px;
    margin: 0;
    padding: 0;
}

.resources-link {
    text-decoration: none;
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    font-family: "Inter Light";
    font-size: 14px;
    transition: all 0.3s ease;
    padding: 10px;
    border-radius: 5px;
}

.resources-link:hover {
    background-color: var(--mainblue);
}

.resource-img {
    width: 25px;
    margin: 0;
    padding: 0;
    pointer-events: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
}


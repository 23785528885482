.rag-get-started-container {
    width: 50%;
    height: 50%;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.rag-get-started-container-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
}

.rag-get-started-container h1 {
    font-family: 'Inter Light';
    color: black;
    font-size: 2rem;
    margin: 0;
    padding: 0;
}

.rag-get-started-container img {
    width: 35px;
    border-radius: 7.5px;
    margin: 0;
    padding: 0;
    padding: 7.5px;
}

.rag-get-started-container ul {
    margin: 0;
    padding: 0;    
}

.rag-get-started-container ul li {
    color: black;
    font-family: 'Inter Light';
    list-style: none;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
}
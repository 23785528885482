#pricing-outer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
}

.pricing-route-container {
    background-color: #fff;
    background-image: url(../assets/q5.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.pricing-inner {
    margin-top: 150px;
    margin-bottom: 150px; 
    width: 100%;
    max-width: 1100px;
}

.pricing-text-container {
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}

.pricing-text-container h1 {
    font-family: "DM Sans", sans-serif;
    font-weight: 350;
    font-size: 4rem;
    padding-bottom: 25px;
    text-align: center;
}

.pricing-text-container small {
    font-family: "Inter Light";
    font-size: 16px;
    padding: 10px;
    text-align: center;
}

.pricing-text-container p {
    font-family: "Inter Light";
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.beta-message-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 5px;
}

.beta-message-container img {
    width: 20px;
    margin-right: 7.5px;
}

.beta-in-progress {
    font-family: "Inter Light";
    font-size: 14px;
    border-radius: 5px;
    color: black;
}

.pricing-tile-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-tile {
    color: black;
    background-color: white;
    width: 325px;
    height: 550px;
    border: 1px solid white;
    border-radius: 25px;
    margin: 20px;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.pricing-tile-inner {
    margin: 25px;
}

.pricing-tile-inner p {
    font-family: "Inter Light";
    font-size: 0.85rem;
}

.pricing-tile h4 {
    padding: 15px;
    padding-left: 0;
    margin: 0;
    font-size: 2rem;
    font-family: "Inter Light";
}

.pricing-tile small {
    padding: 15px;
    padding-left: 0;
    margin: 0;
    font-family: "Inter Light";
    color: var(--cadetGrey);
}

.price-amount-container {
    padding: 30px;
    padding-left: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.price-amount-container h2 {
    font-size: 2rem;
    font-family: "Space Grotesk Light";
    margin: 0;
    padding-right: 5px;
}

.pricing-tile ul {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    width: 100%;
    border-top: 1px solid var(--platinum);
    border-bottom: 1px solid var(--platinum);
}

.pricing-tile ul li {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pricing-tile ul li img {
    width: 25px;
    margin: 5px;
    padding: 7.5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 50%;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
}

.pricing-tile ul li small {
    padding-left: 10px;
}

.pricing-tile button {
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    width: 50%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter Regular";
}

.pricing-tile button img {
    height: 20px;
    margin: 0;
    margin-left: 5px;
    padding: 0;
    transition: all 0.3s ease;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
}

.pricing-tile button:hover  .ra2{
    transform: translate(10px);
}

/* ------------------------------------------------------------------------------------------------------------- */



.pricing-tile2 {
    color: black;
    background: linear-gradient(45deg,#d1aad7 .11%,#c88bc4 25.06%,#7b8fdd 50%,#86bff2 74.8%,#bbdef2 99.76%);
    background-size: 400% 400%;
	animation: gradient 5s ease infinite;
    animation-direction: alternate;
    width: 325px;
    height: 550px;
    border: 1px solid transparent;
    border-radius: 25px;
    margin: 20px;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pricing-img-title {
    width: 30px;
    padding: 7.5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 50%;
    background-color: white;
    margin: 0;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
}

.pricing-tile2 h4 {
    padding: 15px;
    padding-left: 0;
    margin: 0;
    font-size: 2rem;
    font-family: "Inter Light";
}

.pricing-tile2 small {
    padding: 15px;
    padding-left: 0;
    margin: 0;
    font-family: "Inter Light";
    color: var(--jet);
}

.pricing-tile2 ul {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    width: 100%;
    border-top: 1px solid var(--platinum);
    border-bottom: 1px solid var(--platinum);
}

.pricing-tile2 ul li {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pricing-tile2 ul li img {
    width: 25px;
    margin: 5px;
    padding: 7.5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 50%;
    background-color: white;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
}

.pricing-tile2 ul li small {
    padding-left: 10px;
}

.pricing-tile2 button {
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    width: 50%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter Regular";
}

.pricing-tile2 button img {
    height: 20px;
    margin: 0;
    margin-left: 5px;
    padding: 0;
    transition: all 0.3s ease;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
}

.pricing-tile2 button:hover  .ra2{
    transform: translate(10px);
}

.best-value {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.best-value-text {
    font-size: 12px;
    font-family: "Inter Light";
    padding: 5px;
}

.best-value-text-container {
    background-color: white;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}   

.best-value-text-container img {
    width: 15px;
    margin: 0;
    padding: 5px;
    padding-right: 0;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
}

.pricing-footer {
    height: 200px;
    width: 100%;
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.pricing-footer p {
    font-family: "Inter Light";
    font-size: 12px;
    width: 50%;
}

.pricing-footer a {
    color: var(--mainblue);
}

.pricing-footer a:hover {
    text-decoration: none;
}

.pricing-footer-faq {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.pricing-footer-faq span {
    font-family: "Inter Light";
    color: black;
    background-color: white;
    padding: 10px;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 25px;
    border: 1px solid transparent;
    transition: all 0.3s ease;
}

.pricing-footer-faq span:hover {
    background-color: black;
    color: var(--mainblue);
    border-color: var(--mainblue);
    box-shadow: rgba(0, 255, 225, 0.25) 0px 15px 30px -12px;
}

.pricing-footer-faq h6 {
    padding: 30px;
    font-size: 1.5rem;
    font-family: "Space Grotesk Light";
}

@media (max-width: 768px) {
    .pricing-tile-container {
        flex-direction: column;
    }

    .beta-message-container p {
        font-size: 85%;
    }

    .pricing-footer {
        flex-direction: column;
        height: 50vh;
    }
    .pricing-footer p {
        width: 80%;
    }
    .pricing-footer-faq {
        width: 100%;
    }
}

.yearly-monthly-selector-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.yearly-monthly-selector-button-container {
    border-radius: 50px;
    height: 80%;
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.selected-pricing-button {
    margin: 0;
    padding: 0;
    width: 45%;
    height: 85%;
    margin: 5px;
    border-radius: 25px;
    outline: none;
    border: 1px solid #f0f0f0;
    font-family: 'Inter Light';
    background: linear-gradient(45deg,#d1aad7 .11%,#c88bc4 25.06%,#7b8fdd 50%,#86bff2 74.8%,#bbdef2 99.76%);
    background-size: 400% 400%;
	animation: gradient 5s ease infinite;
    animation-direction: alternate;
    cursor: pointer;
}

.unselected-pricing-button {
    margin: 0;
    padding: 0;
    width: 45%;
    height: 85%;
    margin: 5px;
    border-radius: 25px;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'Inter Light';
    cursor: pointer;
    transition: all 0.2s ease;
}

.unselected-pricing-button:hover {
    background-color: #f0f0f0;
}

.free-trial-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.free-trial {
    font-size: 1rem;
    font-family: "Inter Light";
    color: black;
    margin: 0;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    background: linear-gradient(45deg,#d1aad7 .11%,#c88bc4 25.06%,#7b8fdd 50%,#86bff2 74.8%,#bbdef2 99.76%);
    background-size: 400% 400%;
	animation: gradient 5s ease infinite;
    animation-direction: alternate;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 100% 50%;
	}
}

.navigation-outer {
    position: fixed;
    width: 100%;
    background-color: #fff;
    backdrop-filter: blur(5px);
    z-index: 1000;
    border-bottom: 0.1px solid #8293996f;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation-banner {
    width: 100%;
    height: 7.5vh;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
}

.navigation-logo {
    display: flex;
    justify-content: baseline;
    align-items: center;
    margin: 0;
    margin-left: 30px;
}

.navigation-logo-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tdp-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tdp-first {
    font-family: 'DM Sans';
    font-weight: 200;
    font-size: 1.5rem;
    color: black;
    margin-left: 10px;
}

.tdp-second {
    font-size: 0.75rem;
    font-family: 'DM Sans';
    color: var(--mainblue);
    padding: 3px;
    padding-right: 7.5px;
    padding-left: 7.5px;
    margin-left: 7.5px;
    border: 1px solid var(--mainblue);
    border-radius: 5px;
}

.navigation-logo img {
    display: flex;
    justify-content: center;
    align-items: center;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
    padding: 5px;
    border-radius: 5px;
    margin: 0;
    width: 30px;
    background-color: black;
}


.navigation-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.navigation-list ul {
    display: flex;
    list-style: none;
    font-family: "Inter Light";
    padding: 0;
    margin: 0;
    font-size: 16px;
}

.navigation-list ul li {
    color: black;
    background: 
      linear-gradient(to right, transparent, transparent),
      linear-gradient(to right, var(--mainblue),var(--mainblue));
    background-size: 100% 100%, 0 100%;
    background-position: 100% 100%, 50% 100%;
    background-repeat: no-repeat;
    transition: all 0.2s ease;
    text-decoration: none;
    -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  margin: 7.5px;
  padding: 3px;
  padding-left: 7.5px;
  padding-right: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.navigation-list ul li:focus,
.navigation-list ul li:hover {
    cursor: pointer;
    background-size: 0 100%, 100% 100%;
    opacity: 200%;
    color: var(--eerieblack);
}

.navigation-list ul li:hover .down-nav {
    transform: rotate(-180deg);
} 

.logins {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s ease; /* Add transition for background color */
    position: relative;
    z-index: 1; 
}

.logins.active .account-avatar {
    background-color: rgba(0, 0, 0, 0.137); /* Change to your desired background color */
}

.logins-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 160px;
    transform: translateX(-90%);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 2;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
}

.logins-dropdown.visible {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    color: black;
    max-height: 500px; /* Adjust this value based on your content */
    opacity: 1;
    visibility: visible;
    border: 2px solid #f0f0f0;
}

.logins .account-avatar {
    width: 27px;
    border-radius: 50%;
    margin: 5px;
    padding: 5px;
    transition: all 0.2s ease;
    cursor: pointer;
    background-color: black;
}

.logins .account-avatar:hover {
    background-color: var(--eerieblack);
}

.dropdown-element {
    color: black;
    transition: all 0.2s ease;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    font-family: "Inter Light";
    font-size: 16px;
    cursor: pointer;
}

.dropdown-element label {
    cursor: pointer;
    font-size: 14px;
    color: black;
}

.dropdown-element img {
    width: 25px;
    margin: 0;
    padding: 0;
}

.dropdown-element:hover {
    background-color: var(--mainblue);
}


.gs-btn {
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    border: none;
    color: black;
    font-family: "Inter Light";
    font-size: 16px;
    border: 1px solid var(--mainblue);
    background-color: var(--mainblue);
    transition: all 0.2s ease;
    cursor: pointer;
    margin: 5px;
    box-shadow: rgba(0, 255, 225, 0.5) 5px 5px, rgba(0, 255, 225, 0.25) 10px 10px;
}

.logins.active .account-avatar {
    background-color: var(--eerieblack);
}

.logins-dropdown.visible {
    visibility: visible;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    color: black;
}

.gs-btn:focus,
.gs-btn:hover {
    cursor: pointer;
    /* background-color: black;
    color: var(--mainblue); */
    box-shadow: none;
}

.li-btn {
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    color: var(--mainblue);
    font-family: 'Inter Light';
    font-size: 1rem;
    border: 1px solid var(--mainblue);
    transition: all 0.2s ease;
    cursor: pointer;
    background-color: transparent;
}

.li-btn:focus,
.li-btn:hover {
    cursor: pointer;
    background-color: var(--mainblue);
    color: black;
}

.btc-ticker {
    color: #f18805;
    font-family: 'Space Grotesk Light';
    -webkit-user-select: none;
}

.signup-navigation {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: fixed;
    max-height: 100px;
}

.signup-nav-inner {
    width: 100%;
    max-width: 1100px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.currentuser {
    background-color: black;
    color: var(--mainblue);
    width: 80%;
    margin: 0;
    overflow: hidden;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    font-family: "Inter Light";
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.nav-account-btns {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-signin-btn {
    margin: 5px;
    padding: 5px;
    width: 75px;
    border-radius: 3px;
    background-color: #f0f0f0;
    border: none;
    color: black;
    font-family: "Inter Light";
    cursor: pointer;
    transition: all 0.3s ease;
}

.nav-signin-btn:hover {
    opacity: 75%;
}

.nav-signup-btn {
    margin: 5px;
    padding: 5px;
    width: 100px;
    background-color: var(--mainblue);
    border: 1px solid var(--mainblue);
    border-radius: 3px;
    color: black;
    font-family: "Inter Light";
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: rgba(0, 255, 225, 0.5) 5px 5px, rgba(0, 255, 225, 0.25) 10px 10px;
}

.nav-signup-btn:hover {
    box-shadow: 0 0 15px 5px #00ffe168;
}

.burger-nav {
    display: none;
    transition: all 0.3s ease;
}

@media (max-width: 768px) {
    .navigation-banner {
        background-color: white;
        border-bottom: 1px solid white;
        justify-content: space-around;
        width: 100%;
    }

    .navigation-logo {
        margin-left: 15px;
    }

    .navigation-list {
        display: none;
    }
    .nav-account-btns {
        display: none;
    }
    .account-avatar {
        display: none;
    }
    .logins-dropdown.visible {
        display: none;
    }
    .logins.active {
        display: none;
    }
    .burger-nav {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 0;
    }
    /* Default styles for the menu */
.menu {
    visibility: visible;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 7.5vh;
    height: 92.5vh;
    background-color: white;
    z-index: 1000;
    border-top: 2px solid var(--mainblue);
  }
  
  .menu a {
    text-decoration: none;
    color: black;
    padding: 10px;
    font-family: "Inter Light";
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu a:hover {
    background-color: white;
    color: black;
  }
  
}

.ham-link-mobile {
    text-decoration: none;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
}

.menu ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 70%;
    margin-top: 20px;
}



.menu img {
    width: 20px;
    -webkit-user-drag: none;
}

.menu label {
    color: black;
    width: 90%;
    height: 30px;
    padding: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: var(--mainblue);
    font-family: "Inter Light";
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    border-radius: 3px;
}

.account-loggedin {
    color: black;
    width: 90%;
    height: 30px;
    background-color: var(--mainblue);
    font-family: "Inter Light";
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    overflow: hidden;
}

@media (max-width: 500px) {
    .navigation-logo {
        margin-left: 0px;
    }
}
.workbench-container {
    margin-left: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
    background-color: white;
}   

.workbench-component-display {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workbench-component-title-container {
    border-bottom: 1px solid #f0f0f0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.workbench-component-title-container h1 {
    font-family: "DM Sans";
    font-weight: 300;
    color: black;
    margin: 0;
    padding: 0;
    margin-left: 50px;
}

.common-container {
    background-color: #f0f0f0; /* Light grey background, change as needed */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
    padding: 20px;
    margin: 10px 0;
    width: 80%;
}

.content-header {
    margin-bottom: 20px;
}

.content-header h2 {
    color: #333; /* Dark grey color for text, change as needed */
    font-size: 24px;
}

.content-body {
    text-align: left;
    color: #666; /* Lighter text color for the body, change as needed */
}


/* --------------------------------------------------------- User LogOut ------------------------------------------------------------ */

.logins2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s ease; /* Add transition for background color */
    position: relative;
    margin-right: 35px;
}

.logins2.active .account-avatar2 {

}

.logins-dropdown2 {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 260px;
    transform: translateX(-90%);
    background-color: #f0f0f0;
    border: 1px solid var(--cadetGrey);
    border-radius: 15px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 2;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
}

.logins-dropdown2 a {
    color: black;
    text-decoration: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logins-dropdown2.visible {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    color: black;
    max-height: 500px; /* Adjust this value based on your content */
    opacity: 1;
    visibility: visible;
}

.logins2 .account-avatar2 {
    width: 27px;
    border-radius: 7.5px;
    margin: 5px;
    padding: 5px;
    transition: all 0.2s ease;
    cursor: pointer;
    -webkit-user-drag: none;
}

.logins2 .account-avatar2:hover {
    background-color: #f0f0f0;
}

.dropdown-element2 {
    color: black;
    transition: all 0.2s ease;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 5px;
    padding: 4px;
    margin: 5px;
    font-family: "Inter Light";
    font-size: 16px;
    cursor: pointer;
}

.dropdown-element2 label {
    cursor: pointer;
    font-size: 14px;
    color: black;
    margin: 0;
    padding: 10px;
    padding-left: 0;
}

.dropdown-element2 img {
    width: 24px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-user-drag: none;
}

.dropdown-element2:hover {
    background-color: var(--mainblue);
}


.logins2.active .account-avatar2 {
}

.logins-dropdown2.visible {
    visibility: visible;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    color: black;
}

/* --------------------------------------------------------- User LogOut ------------------------------------------------------------ */
.chatbottool-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.chatbottool-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: #f0f0f0;
}

.chatbottool-input-container img {
    width: 25px;
    cursor: pointer;
    -webkit-user-drag: none;
}

.chatbottool-input-container input[type="file"] {
    display: none;
}

.chatbottool-input-container input {
    width: 75%;
    height: 1rem;
    line-height: 1rem;
    padding: 10px;
    margin: 10px;
    resize: none;
    outline: none;
    font-size: 1rem;
    font-family: "Inter Light";
    border-radius: 10px;
    border: 1px solid #8293996f;
    transition: all 0.3s;
}

.chatbottool-input-container input:focus,
.chatbottool-input-container input:hover {
    border: 1px solid var(--mainblue);
}

.chatbottool-input-container small {
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "Inter Light";
}

.chatbottool-input-container label {
    padding: 5px;
    margin-right: 5px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.3s;
}

.chatbottool-input-container label:hover {
    background-color: var(--mainblue);
}


.pulse-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Add this line */
    width: 150px;
    margin: 5px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    border-radius: 5px;
    background-color: black;
}

.pin3 {
    width: 3px;
    height: 3px;
    /* position: absolute; Remove this line */
    /* z-index: 10; Remove this line */
    background: #00ffe1;
    border: 1px solid #00ffe1;
    border-radius: 50%;
    margin-left: 10px;
}

.pin-effect3 {
    width: 15px;
    height: 15px;
    background: #00ffe175;
    display: block;
    border-radius: 50%;
    opacity: 1;
    animation: pulsate 2.4s ease-out infinite;
    position: absolute; /* Add this line */
    left: 5px; /* Position to the right of pin2 */
    margin: 0;
    padding: 0;
}

.pin4 {
    width: 3px;
    height: 3px;
    /* position: absolute; Remove this line */
    /* z-index: 10; Remove this line */
    background: #FFC30E;
    border: 1px solid #FFC30E;
    border-radius: 50%;
    margin-left: 10px;
}

.pin-effect4 {
    width: 15px;
    height: 15px;
    background: #ffc30e90;
    display: block;
    border-radius: 50%;
    opacity: 1;
    animation: pulsate 2.4s ease-out infinite;
    position: absolute; /* Add this line */
    left: 5px; /* Position to the right of pin2 */
    margin: 0;
    padding: 0;
}

.ready-pulse-p {
    color: var(--mainblue);
    font-size: 12px;
    font-family: "IBM Plex Mono Light";
}

.waiting-pulse-p {
    color: #FFC30E;
    font-size: 12px;
    font-family: "IBM Plex Mono Light";
}

@keyframes pulsate {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        ransform: scale(1.2);
        opacity: 0;
    }
    }

.chatbot-browser-inference-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbot-browser-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #8293996f;
}

.chatbot-inference-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: black;
    text-align: left;
}

.pdf-document {
    max-height: 80vh;
    border-radius: 10px;
    border: 1px solid #8293996f;
    padding: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations {
    display: none;
}

.chatbot-pdf-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.chatbot-pdf-pagination span {
    font-family: "Inter Light";
    font-size: 1rem;
    margin: 0 5px;
    color: black;
}

.chatbot-pdf-pagination button {
    background-color: var(--mainblue);
    border: none;
    color: black;
    font-size: 1rem;
    font-family: "Inter Light";
    margin: 0 5px;
    padding: 7.5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.chatbot-pdf-pagination button:hover {
    background-color: #00ffe175;
}

.chatbot-inference-inner-container {
    width: 100%;
    margin-top: 25px;
}

.chatbot-inference-answer-container {
    width: 85%;
    min-width: 85%;
    height: 100%;
    max-height: 80vh;
    overflow-y: scroll;
}

.chatbot-inference-answer {
    width: 100%;
    word-wrap: break-word;
    margin: 0;
    margin-top: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.chatbot-inference-question {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 0;
}

.chatbot-answer-image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f0f0f0;
    margin: 0;
    padding: 10px;
    border-radius: 10px;
}

.chatbot-answer-image-container p {
    font-family: "Inter Light";
    font-size: 1rem;
    color: black;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    text-align: left;
    width: 100%;
}

.chatbot-inference-question-asker-container {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbot-inference-question-asker-inner {
    width: 37.5%;
    border: 1px solid #8293996f;
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    position: absolute;
    bottom: 0%;
    background-color: white;
}

.chatbot-inference-question-asker-container textarea {
    text-align: left;
    border: none;
    outline: none;
    width: 85%;
    margin: 10px;
    font-size: 1rem;
    font-family: "DM Sans";
    font-weight: 300;
    resize: none;
    overflow-y: auto;
    max-height: 200px;
}

.chatbot-inference-question-asker-container button {
    width: 35px;
    height: 35px;
    background-color: transparent;
    outline: none;
    padding: 2px;
    margin: 0;
    margin-right: 5px;
    border-radius: 20px;
    border: 1px solid #8293996f;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbot-inference-send-button-img {
    transform: rotate(-90deg);
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    transition: all 0.3s ease;
    -webkit-user-drag: none;
  }
  
.chatbot-inference-question-asker-container button:hover {
    background-color: #00ffe175;
}

.chatbot-inference-question-asker-container button:hover .chatbot-inference-send-button-img {
    translate: 0px -3px;
}

.webpage-screenshot {
    width: 85%;
    height: auto;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #8293996f;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.webpage-screenshot-message {
    font-family: "Inter Light";
    font-size: 1rem;
    color: black;
    margin: 0;
    padding: 0;
    padding-top: 25px;
    text-align: center;
    width: 100%;
}

.webpage-screenshot-loader {
    width: 100px;
    background-color: black;
    padding: 10px;
    border-radius: 10px;
}

.chatbot-inference-answer-container::-webkit-scrollbar {
    width: 8px;
}

.chatbot-inference-answer-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

.chatbot-inference-answer-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.chatbot-inference-answer-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.status-message {
    width: 90%;
    height: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin-top: 5px;
    padding: 10px;
}

.status-message p {
    margin: 0;
    padding: 0;
    font-family: "Inter Light";
}

.status-message img {
    margin: 0;
    padding: 0;
    width: 17.5px;
}
.signin-container {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.signin-left {
    width: 50%;
    margin-bottom: 5%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin-form {
    height: 600px;
    background-color: white;
    border: 2px solid #f0f0f0;
    border-radius: 5px;;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 65%;
    max-width: 500px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.signin-form h1 {
    font-family: "IBM Plex Mono Extra Light";
    font-size: 4rem;
    width: 80%;
}

.signin-form h2 {
    font-family: "IBM Plex Mono Extra Light";
    font-size: 3rem;
    width: 80%;
}

.label-ipt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 75%;
    height: 100px;
    padding: 3%;
}

.label-ipt-container label {
    width: 80%;
    color: black;
    font-size: 12px;
    font-family: "Inter Light";
    padding-bottom: 5px;
}

.label-ipt-container input {
    width: 63.5%;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    padding-right: 50px;
    font-size: 14px;
    font-family: "Inter Light";
    outline: none;
}

.label-ipt-container input:focus,
.label-ipt-container input:hover {
    border-color: var(--mainblue);
}

.signin-left small {
    font-size: 12px;
    width: 80%;
    padding-top: 40px;
    padding-bottom: 40px;
    color: black;
}

.label-ipt-container a {
    font-family: "Inter Regular";
    color: black;
    text-decoration: underline 0.25px;
    text-underline-offset: 3px;
    cursor: pointer;
}

.login-button {
    width: 60%;
    padding: 10px;
    height: 40px;
    font-size: 14px;
    font-family: "Inter Light";
    border: none;
    border-radius: 5px;
    background-color: var(--mainblue);
    cursor: pointer;
    transform: all 0.3s ease;
}

.dhac {
    width: 100%;
    font-size: 12px;
    font-family: "Inter Light";
    text-align: center;
}

.dhac a {
    text-decoration: none;
    color: black;
    border: 0.25px solid black;
    padding: 3px;
    border-radius: 3px;
    transition: all 0.2s ease;
}

.dhac a:hover {
    color: var(--mainblue);
    background-color: black;
}


.tdp-title-container3 {
    width: 82%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 25px;
    padding-left: 0;
    padding-right: 0;
}

.tdp-first3 {
    font-size: 16px;
    font-family: "DM Sans";
    font-weight: 250;
    color: black;
    padding: 3px;
    margin-left: 2%;
}

.tdp-second3 {
    margin: 0;
    padding: 4px;
    background-color: black;
    border-radius: 5px;
    width: 30px;
    left: 0;
}

.password-toggle2 {
    position: absolute;
    right: 42.5px;
    top: 55%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .password-toggle2 img {
    width: 20px;
    height: 20px;
    outline: none;
  }

  .input-icon3 {
    position: absolute;
    right: 65px;
    top: 54%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .input-icon2 {
    position: absolute;
    right: 42.5px;
    top: 55%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .input-icon2 img,
  .input-icon3 img {
    width: 20px;
    height: 20px;
    outline: none;
}

.input-with-icon input {
}
  
@media (max-width: 768px) {
    .signin-left {
        width: 87.5%;
    }

    .signin-form {
        width: 100%;
    }

    .input-icon3 {
        right: 40px;
    }

    .password-toggle2 {
        right: 20px;
    }

    .input-icon2 {
        right: 20px;
    }
}
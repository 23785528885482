.error-message-overlay {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  padding: 10px;
  background-color: var(--mainblue);
  color: black;
  font-family: "Inter Light";
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  border-radius: 5px;
}

.error-message-overlay.active {
  opacity: 1;
  visibility: visible;
}

.error-message-overlay::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 10%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: var(--mainblue) transparent transparent transparent;
}

.navigationv2 {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    transition: width .15s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #f0f0f0;
    text-align: center;
    width: 200px;
}
  
.navigationv2:hover {
    cursor: pointer;
}

.navigationv2-top-container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.unselectedButton, .selectedButton {
    background-color: transparent;
    border: none;
    color: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    width: 80%;
    margin: 0;
    text-align: left;
    border-radius: 7.5px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: background-color 0.15s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.unselectedButton {
    border: 1px solid #8293996f;
}

.selectedButton {
    background-color: #00ffe175;
    border: 1px solid transparent;
}



.navigationv2-tool-selector {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
}

.logo-v2-container {
    width: 100%;
    height: 75px;
    margin-left: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo-v2-container a {
    width: 27.5px;
    height: 27.5px;
    margin: 0;
}

.logo-v2 {
    width: 27.5px;
    height: 27.5px;
    margin: 0;
    padding: 5px;
    border-radius: 5px;
    background-color: black;
    -webkit-user-drag: none;
}

.unselectedButton:hover {
    background-color: #f0f0f0; /* Light grey background for hover */
}

.button-text {
    margin: 0;
    padding: 0;
    color: var(--cadetGrey);
    font-family: "Inter Light";
}

.selectedButton .button-text {
    color: var(--jet2);
}

.navigationv2 button img {
    width: 20px; /* Example size, adjust as needed */
    height: 20px;
    margin: 0;
    padding: 0;
    margin-right: 20px;
    -webkit-user-drag: none;
}

.navigationv2-tool-selector small {
    font-family: 'Inter Light';
    padding: 7.5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #F0F0F0;
    width: 75%;
}

.navigationv2-bottom-container {
    padding: 7.5px;
    width: 80%;
    margin: 0;
    text-align: left;
    border-radius: 7.5px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.navigationv2-bottom-container p {
    font-family: "IBM Plex Mono Light";
    font-size: 0.85rem;
}

.reconnecting {
    color: #FFC30E;
}

.connected {
    color: var(--mainblue);
}

.navigationv2-bottom-container img {
    width: 25px;
    margin: 0;
    padding: 0;
}
